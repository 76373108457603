<template>
  <div>
    <NavigationBar
      titel="Neue Kategorie erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
        class="col s8"
      >
        <category-editor v-model="category" mode="new"></category-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { MY_ORGANIZATION } from "@/store/modules.js";
import { CREATE_GUARDBOOK_CATEGORY } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import CategoryEditor from "@/components/my-organization/guardbook/GuardbookCategoryEditor.vue";

export default {
  name: "my-organization-guardbook-entry-new",
  components: {
    NavigationBar,
    CategoryEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      category: {},
    };
  },

  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MY_ORGANIZATION}${CREATE_GUARDBOOK_CATEGORY}`, {
          organizationId: this.$route.params.organizationId,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
