<template>
  <div>
    <NavigationBar
      titel="Wachbuch"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <TippCard hintID="Meine_Organisation_Wachbuch_1_V1" text=""> </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-clipboard-text" title="Alle Einträge">
              </Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="headers"
                  :items="guardbookEntries"
                  item-key="meta.id"
                  multi-sort
                  sort-by="zeitraum.start.firebaseFormat"
                  :sort-desc="true"
                  :footer-props="footerProps"
                  :items-per-page="25"
                  class="elevation-2"
                  max-height="800px auto"
                  @click:row="openEntryDetails"
                >
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-shape"
                title="Kategorien"
                :actions="actionsCategories"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="guardbookCategories"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  no-data-text="Keine Kategorien vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col
                        v-for="category in props.items"
                        :key="category.meta.id"
                        cols="12"
                      >
                        <v-card link @click="openCategoryDetails(item.meta.id)">
                          <v-card-title class="subheading">
                            {{ category.title }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ category.description }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import {
  MY_ORGANIZATION_GUARDBOOK_ENTRIES_create,
  MY_ORGANIZATION_GUARDBOOK_CATEGORIES_create,
} from "@/data/permission-types.js";

export default {
  name: "my-organization-guardbook",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
  },
  data() {
    return {
      sortDesc: true,
      sortBy: "title",
      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      actions: [
        {
          title: "Erstellen",
          permission: `${MY_ORGANIZATION_GUARDBOOK_ENTRIES_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: this.createEntry,
        },
      ],
      actionsCategories: [
        {
          title: "Erstellen",
          icon: "mdi-shape-plus",
          permission: `${MY_ORGANIZATION_GUARDBOOK_CATEGORIES_create}`,
          actionStyle: "textBtn",
          function: this.createCategory,
        },
      ],
      headers: [
        { text: "Zeitstemepel", value: "status" },
        { text: "Kategorie", value: "category.title" },
        { text: "Text", value: "text" },
      ],
    };
  },
  computed: {
    guardbookEntries() {
      return this.$store.state.myOrganization.guardbook.entries;
    },
    guardbookCategories() {
      return this.$store.state.myOrganization.guardbook.categories;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "guardbookEntries", data: this.guardbookEntries },
        { title: "guardbookCategories", data: this.guardbookCategories },
      ];
    },
  },
  created() {
    // this.initialize();
  },
  methods: {
    createEntry() {
      this.$router.push({
        name: "my-organization-guardbook-entry-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
    createCategory() {
      this.$router.push({
        name: "my-organization-guardbook-entry-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
    openEntryDetails(id) {
      this.$router.push({
        name: "my-organization-guardbook-entry-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: id,
        },
      });
    },
    openCategoryDetails(id) {
      this.$router.push({
        name: "my-organization-guardbook-category-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: id,
        },
      });
    },
  },
};
</script>
